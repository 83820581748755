import React, {useState} from 'react';
import './ContactForm.scss';
import axios from 'axios';

function ContactForm() {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [mailSent, setResponse] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState('');

    function handleFormSubmit(e) {
        
        const form = document.getElementById('contact-form');
        const company = form.elements["company"];
        if (company.value !== '') {
            e.preventDefault(e);
            return;
        }
        if (form.checkValidity()) {
            e.preventDefault(e);
            setSending(true);

            const formData = {
                name,
                email,
                message,
        		isFromFooterWebsite: true
      };

            axios({
                method: 'post',
                url: process.env.REACT_APP_SEND_EMAIL_API_URL,
                headers: { 'content-type': 'application/json' },
                data: formData
            })
                .then(result => {
                    setResponse(result.data.data.message);
                    setSending(false);
                })
                .catch(error => {
                    setError(error.message);
                    setSending(false);
                });
        }

    }

    return (
    <div>
        {error !== "" ? <div>Failed to send your message. Please try again.</div>
         : (mailSent ?<div>Message successfully sent! {error}</div>
            : <form id="contact-form" action="#" method="post" className="contact-form">
                <input
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Name & Surname"
                    required
                    onChange={e => setName(e.target.value)} />
                <input
                    type="text"
                    name="company"
                    value={company}
                    style={{ display: 'none' }}
                    placeholder="Company"
                    onChange={e => setCompany(e.target.value)} />   {/*This one is added to prevent bots to submit the form*/}
                <input
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    required
                    onChange={e => setEmail(e.target.value)} />
                <textarea
                    value={message}
                    name="message"
                    placeholder="Message"
                    required
                    onChange={e => setMessage(e.target.value)} />
                        {sending ?
                            (<div className="spinner-border" role="status" style={{ float: "right" }}>
                                <span className="sr-only">Loading...</span>
                            </div>) :
                        <button onClick={(e) => handleFormSubmit(e)} type="submit">Send</button>}
            </form>)
        }
    </div>
    );
}

export default ContactForm;