import React, { useEffect, useState } from "react";
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";
import Lottie from 'react-lottie';
import careerAnimation from '../../../assets/lotties/career.json';
import { lottieOptions } from '../../../config/PluginSettings';
// import JobOffers from '../../../api/JobOffers';
import firebase from "./../../../Firebase";
import "firebase/firestore";

function CareerSection() {
    //   const [postionsTitle, setPostionsTitle] = useState();
    const [JobOffers, setJobOffers] = useState([]);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        const db = firebase.firestore();
        return db
            .collection("job-ads")
            .where("active", "==", true)
            .onSnapshot((snapshot) => {
                const jobs = [];
                snapshot.forEach((doc) => jobs.push({ ...doc.data(), id: doc.id }));
                setJobOffers(jobs);
                setLoaded(true);
            });
    }, []);
    return (
        <section id="career" className="row section-career">
            {isLoaded ? (
                <div className="offset-lg-1 col-lg-7">
                    <DescriptionBlock
                        header="Careers"
                        title={
                            JobOffers.length === 0
                                ? "We currently have no positions available, but feel free to contact us at info@propeller.ba"
                                : JobOffers.length === 1
                                    ? "We have " + JobOffers.length + " open position"
                                    : "We have " + JobOffers.length + " open positions"
                        }
                        buttonText="join us"
                        navTo="careers"
                    />
                </div>
            ) : (
                ""
            )}
            <div className="offset-lg-2 col-lg-8">
                <div className="animation-container animation-career">
                    <Lottie
                        options={{ ...lottieOptions, animationData: careerAnimation }}
                    />
                </div>
            </div>
        </section>
    );
}

export default CareerSection;
