import React from 'react';
import {Helmet} from 'react-helmet';
import DescriptionBlock from '../components/LayoutElements/DescriptionBlock/DescriptionBlock';

import OurStoryImage from './../assets/images/subsection-our-story.svg';

function OurStory() {
    return (
        <div className="main-content" id="our-story">
            <div className="container">
                <Helmet>
                    <title>Our Story - Propeller</title>
                    <meta name="description"
                          content="Innovate, explore, build-upon our and our clients' ideas"/>
                </Helmet>

                <section className="row">
                    <div className="col-12 col-lg-6">
                        <DescriptionBlock
                            isLandingBlock={true}
                            header="Our Story"
                            title="Innovate, explore, build-upon our and our clients' ideas"
                            center={true}
                        />
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-1 mb-3">
                        <div className="row">
                            <div className="col-12">
                                <img src={OurStoryImage} className="img-fluid mx-auto d-block w-100" alt="Our Values"/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section id="values-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="mb-4">Today's world is a good place to be in</h2>
                            <p>Pace of innovation is ever-increasing in our society. Far-flung concepts of yesterday -
                                Machine
                                learning, IoT & Augmented reality - are becoming today’s reality. Only thing we can be
                                certain
                                of is that the change will continue and that we continuously need to adapt to these
                                changes.</p>

                            <p>Accomplishing things is hard. It takes a lot of work and will-power. We work only with
                                people
                                and companies who believe in themselves and who are willing to commit. Above everything
                                else,
                                we
                                believe in the power of the individual to accomplish things and to grow while doing
                                this. We
                                don’t care about past merits, we will rather measure you on your potential.</p>

                            <p>Propeller is envisioned as an innovation vehicle where its founders, employees and
                                clients
                                can
                                explore and build-upon ideas and visionary concepts. No concept or idea is too grand to
                                realize.
                                Everything is worth exploring.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OurStory;