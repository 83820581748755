import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";

import './DescriptionBlock.scss';

function DescriptionBlock(props) {
		const landingClass = props.isLandingBlock ? 'landing' : '';
		const centerClass = props.center ? 'center' : '';
		const darkTheme = props.darkTheme ? 'dark' : '';

		return (
				<div className={`description-block ${landingClass} ${centerClass} ${darkTheme}`}>
						{props.header && <h3>{props.header}</h3>}
						{props.title && <h2>{props.title}</h2>}
						{props.description && <p>{props.description}</p>}
						{props.buttonText && <NavLink end={true} to={props.navTo} className="link-default">{props.buttonText}</NavLink>}
				</div>
		);
}

DescriptionBlock.propTypes = {
		navTo: PropTypes.string,
		isLandingBlock: PropTypes.bool,
		center: PropTypes.bool,
		darkTheme: PropTypes.bool,
		header: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		buttonText: PropTypes.string,
		imageSrc: PropTypes.string
};

DescriptionBlock.defaultProps = {
		isLandingBlock: false
};

export default DescriptionBlock;