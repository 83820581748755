import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./assets/sass/app.scss";
import "./assets/images/facebook.png";

import MenuItems from "./config/MenuItems";
import MainNavbar from "./components/LayoutElements/MainNavbar/MainNavbar";
import MainMenu from "./components/LayoutElements/MainMenu/MainMenu";
import MainFooter from "./components/LayoutElements/MainFooter/MainFooter";
import CookieConsent from "react-cookie-consent";

import Home from "./views/Home";
import OurValues from "./views/OurValues";
import OurStory from "./views/OurStory";
import Career from "./views/CareerView/CareerView";
import HowWeWork from "./views/HowWeWork";
import NotFound from "./views/NotFound";
import ScrollToTop from "./config/ScrollToTopController";

function App() {
    const [menuIsOpen, toggleMenu] = useState(false);
  useEffect(() => {});

  return (
      <Router>
          <ScrollToTop />
        <MainNavbar
            onMenuToggle={() => toggleMenu(!menuIsOpen)}
            menuIsOpen={menuIsOpen}
            closeMenu={() => toggleMenu(false)}
        />
        <MainMenu
            isOpen={menuIsOpen}
            menuItems={MenuItems}
            closeMenu={() => toggleMenu(false)}
          />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="our-values" element={<OurValues />} />
            <Route path="our-story" element={<OurStory />} />
            <Route path="careers" element={<Career />} />
            <Route path="how-we-work" element={<HowWeWork />} />
            <Route path="*" element={<NotFound />}/>
        </Routes>
          <MainFooter />
          <CookieConsent location="bottom" buttonText="GOT IT!" containerClasses="consent-container" buttonClasses="consent-button">
              <div>
                  <h3>We use cookies</h3>
                  <div>This website uses cookies to ensure you get the best user experience. By continuing to use this website, you agree to their use.</div>
                  <div>Happy propelling!</div>
              </div>
          </CookieConsent>
    </Router>
  );
}

export default App;
