import React from 'react';
import './MainFooter.scss';

import linkedInIcon from '../../../assets/images/i-lin.svg';
import facebookIcon from '../../../assets/images/i-fb.svg';
import instagramIcon from '../../../assets/images/i-inst.svg';
import ContactForm from "../../ContactForm/ContactForm";

function MainFooter() {
	const currentYear = new Date().getFullYear();
	return (
		<footer className="main-footer">
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<h2>Propeller d.o.o.</h2>
						<ul className="contact-info">
							<li>Hamdije Kreševljakovića 64</li>
							<li>71000 Sarajevo</li>
							<li>Tel. <a href="tel:+387 61 250 728">+387 61 250 728</a></li>
							<li>e-mail: <a href="mailto:info@propeller.ba">info@propeller.ba</a></li>
						</ul>
					</div>
					<div className="offset-md-1 col-md-3">
						<h3>Connect With Us</h3>
						<ul className="social-links">
							<li><a href="https://www.linkedin.com/company/propellerdoo/" target="_blank"
								rel="noopener noreferrer"><img src={linkedInIcon} alt="Propeller on LinkedIn"/></a>
							</li>
							<li><a href="https://www.facebook.com/propeller.ba" target="_blank"
								rel="noopener noreferrer"><img src={facebookIcon} alt="Propeller on Facebook"/></a>
							</li>
							<li><a href="https://www.instagram.com/propellercompany/" target="_blank"
								rel="noopener noreferrer"><img src={instagramIcon} alt="Propeller on Instagram" /></a>
							</li>
						</ul>
					</div>
					<div className="col-md-5">
						<h3>Get in Touch</h3>
						<ContactForm/>
					</div>
				</div>
				<hr className="separator"/>
				<span className="copyright">Copyright © {currentYear} Propeller d.o.o. All rights reserved.</span>
			</div>
		</footer>
	);
}

export default MainFooter;