import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CareerView.scss";
import DescriptionBlock from "../../components/LayoutElements/DescriptionBlock/DescriptionBlock";
import Accordion from "../../components/LayoutElements/Accordion/Accordion";
import Apply from "./Apply";
import SelectionProcess from "./SelectionProcess";
import Culture from "./Culture";
import { Helmet } from "react-helmet";
import firebase from "./../../Firebase";
import "firebase/firestore";

function CareerView() {
  const [formData, setFormData] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isPositionValid, setIsPositionValid] = useState(false);
  const [isValidBrief, setIsValidBrief] = useState(false);
  const [isValidCvLink, setIsValidCvLink] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [isFileLarge, setIsFileLarge] = useState(false);
  const [isSent, setIsSent] = useState(null);
  const [JobOffers, setJobOffers] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [sending, setSending] = useState(false);
  const [expandedAccItemId, setExpandedAccItemId] = useState(-1);
  let tempFormData;

  useEffect(() => {
    const db = firebase.firestore();
    return db
      .collection("job-ads")
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        const jobs = [];
          snapshot.forEach((doc) => jobs.push({ ...doc.data(), id: doc.id }));
        setJobOffers(jobs);
        setLoaded(true);
      });
  }, []);

    const formSubmit = (e) => {
      const form = document.getElementById("apply-form");
      const company = form.elements["sender_company"];
        if (company.value !== '') {
          e.preventDefault(e);
          return;
        }

        if (form.checkValidity()) {
            e.preventDefault(e);
            setSending(true);

            const applyFormData = {
                name: formData.sender_name,
                email: formData.sender_email,
                message: formData.message,
                subject: formData.position,
                cvlink: formData.cvlink
            };

            axios({
                method: "post",
                url: process.env.REACT_APP_SEND_EMAIL_API_URL,
                headers: { 'content-type': 'application/json' },
                data: applyFormData
            })
                .then((result) => {
                    setFileName(true);
                    setFormData({});
                    setIsSent(true);
                    form.reset();
                    clearForm();
                    setSending(false);
                })
                .catch((error) => {
                    setFileName(null);
                    setIsSent(false);
                    setSending(false);
                });
        }
  };

  useEffect(() => {
    let submitEnabled =
      isValidName &&
      isValidEmail &&
      isValidBrief &&
      isValidCvLink &&
      isPositionValid &&
      Object.keys(formData).length !==0;
    setIsValidForm(submitEnabled);
  }, [
    formData,
    isValidBrief,
    isValidEmail,
    isValidName,
    isValidCvLink,
    isPositionValid,
  ]);

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    tempFormData = formData;
    tempFormData[name] = value;
    checkValues(name, value);
    };

    const clearForm = () => {
        setIsValidBrief(false);
        setIsValidCvLink(false);
        setIsValidEmail(false);
        setIsValidName(false);
        setIsPositionValid(false);
        setIsValidForm(false);
    }

  const clearMessage = () => {
    setFileName(null);
    setIsSent(null);
  };

  const handleDocumentUpload = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    e.target.files[0] ? setFileName(file.name) : setFileName("");
    e.target.files[0] && e.target.files[0].size > 10000000
      ? setIsFileLarge(true)
      : setIsFileLarge(false);
    checkValues(name, file);
    };

    const applyNow = (id) => {
        document.getElementsByClassName('apply-section')[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
            
       handleFormChange({
            target: {
                name: 'position',
                value: JobOffers[id].title
            }
        });   
    };

  const checkValues = (name, value) => {
    switch (name) {
      case "position":
            setFormData({ ...formData, [name]: value });
            if (value === null || value === "")
                setIsPositionValid(false);
            else
                setIsPositionValid(true);
        break;
      case "sender_name":
        setFormData({ ...formData, [name]: value });
            if (value === null || value === "")
                setIsValidName(false);
            else
                setIsValidName(true);
            break;
      case "sender_company":
        setFormData({ ...formData, [name]: value });
        break;
      case "sender_email":
        setFormData({ ...formData, [name]: value });
            if (value === null || value === "")
                setIsValidEmail(false);
            else
                setIsValidEmail(true);
        break;
      case "message":
        setFormData({ ...formData, [name]: value });
            if (value === null || value === "")
                setIsValidBrief(false);
            else
                setIsValidBrief(true);
        break;
      case "cvlink":
        setFormData({ ...formData, [name]: value });
            if (value === null || value === "")
                setIsValidCvLink(false);
            else
                setIsValidCvLink(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="main-content careers" id="career">
      <Helmet>
        <title>Careers - Propeller</title>
        <meta name="description" content="Let’s work together" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10">
            <DescriptionBlock
              isLandingBlock
              header="Careers"
              title="Let’s work together"
              center
            />
          </div>
          <div className="offset-lg-3 col-lg-6">
            <div className="subtitle -gray -center -marginbottom30">
              We are a company where people can grow as individuals and as a
              team.
            </div>
          </div>
        </div>
      </div>

      {JobOffers.length >= 1 ? (
        <div className="highlighted">
          <div className="container">
            <div className="offset-lg-1 col-lg-10">
              <h3 className="title -center">Open positions</h3>
              <div className="accordion">
                {isLoaded
                    ? JobOffers.map((offer, i) => {
                        return (
                            <Accordion
                                key={i}
                                id={i}
                                expandedAccItemId={expandedAccItemId}
                                onExpand={() => setExpandedAccItemId(i)}
                                jobNumber={JobOffers.length}
                                title={offer.title}
                                titleOne={offer.titleOne}
                                titleTwo={offer.titleTwo}
                                titleThree={offer.titleThree}
                                jobDescription={offer.jobDescription}
                                whatWeOffer={offer.whatWeOffer}
                                contact={offer.contact}
                                requirements={offer.requirements}
                                titleFour={offer.titleFour}
                                bonusSkills={offer.bonusSkills}
                                applyNow={(id) =>applyNow(id)}
                            />
                         );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
          )}

      {/* SELECTION PROCESS SECTION STARTS HERE */}
      <div className="selection-process-section">
          <SelectionProcess />
      </div>

      <div className="apply-section">
        <Apply
                  formData={formData}
                  fileName={fileName}
                  isValidCvLink={isValidCvLink}
                  isFileLarge={isFileLarge}
                  isValidForm={isValidForm}
                  isSent={isSent}
                  formSubmit={(e) => formSubmit(e)}
                  tempFormData={tempFormData}
                  handleFormChange={(e) => handleFormChange(e)}
                  handleDocumentUpload={(e) => handleDocumentUpload(e)}
                  clearMessage={(e) => clearMessage(e)}
                  jobOffers={JobOffers}
                  sending={sending}
        />
      </div>


      {/* OUR CULTURE SECTION STARTS HERE */}
      <div className="culture mt-5">
        <Culture />
      </div>
      {/*  
			<div className="careers-slide">
				<div className="thumbnail">
					<InstagramEmbed
						className="col-12 col-md-3"
						url='https://www.instagram.com/p/B-riNrnB1Vt/'
						maxWidth={320}
						hideCaption={false}
						containerTagName='div'
						protocol=''
						injectScript
						onLoading={() => { }}
						onSuccess={() => { }}
						onAfterRender={() => { }}
						onFailure={() => { }}
					/>
				</div>
				<div className="thumbnail">
					<InstagramEmbed
						className="col-12 col-md-3"
						url='https://www.instagram.com/p/B8rHPTVl0jn/'
						maxWidth={320}
						hideCaption={false}
						containerTagName='div'
						protocol=''
						injectScript
						onLoading={() => { }}
						onSuccess={() => { }}
						onAfterRender={() => { }}
						onFailure={() => { }}
					/>
				</div>
				<div className="thumbnail">
					<InstagramEmbed
						className="col-12 col-md-3"
						url='https://www.instagram.com/p/B91eezSlLF6/'
						maxWidth={320}
						hideCaption={false}
						containerTagName='div'
						protocol=''
						injectScript
						onLoading={() => { }}
						onSuccess={() => { }}
						onAfterRender={() => { }}
						onFailure={() => { }}
					/>
				</div>
				<div className="thumbnail">
					<InstagramEmbed
						className="col-12 col-md-3"
						url='https://www.instagram.com/p/B4lDG81A9u0/'
						maxWidth={320}
						hideCaption={false}
						containerTagName='div'
						protocol=''
						injectScript
						onLoading={() => { }}
						onSuccess={() => { }}
						onAfterRender={() => { }}
						onFailure={() => { }}
					/>
				</div>
			</div>
			*/}
    </div>
  );
}

export default CareerView;
