import React from 'react';
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";
import Lottie from 'react-lottie';
import howWeWorkAnimation from '../../../assets/lotties/how_we_work.json';
import { lottieOptions } from '../../../config/PluginSettings';

function HowWeWorkSection() {
	return (
		<section id="how-we-work" className="row">
			<div className="offset-lg-2 col-lg-8">
				<DescriptionBlock
					center={true}
					header="How We Work"
					title="Research, workshop, prototype, test, launch, marketing"
				/>
			</div>
			<div className="offset-lg-1 col-lg-4 mt-lg-5 pt-lg-5">
				<DescriptionBlock
					description="At Propeller we build workflow together with our clients and deliver a full circle of expertise for outstanding products."
					buttonText="How We Work"
					navTo="how-we-work"
				/>
			</div>
			<div className="col-lg-6">
				<div className="animation-container animation-how-we-work">
					<Lottie
						options={{ ...lottieOptions, animationData: howWeWorkAnimation }}
						height={'140%'}
						width={'150%'}
					/>
				</div>
			</div>
		</section>
	);
}

export default HowWeWorkSection;