export const blogSliderSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
				{
						breakpoint: 992,
						settings: {
								slidesToShow: 2
						}
				},
				{
						breakpoint: 576,
						settings: {
								slidesToShow: 1,
								slidesToScroll: 1
						}
				}
		]
};

export const lottieOptions = {
	loop: true,
	autoplay: true,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};