import React from 'react';
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import './MainMenu.scss';

function MainMenu(props) {
    return (
        <TransitionGroup component={null}>
            {props.isOpen && (
                <CSSTransition className="fade main-menu" timeout={100}>
                    <ul>
                        {props.menuItems.map((item, i) =>
                            <li key={i} className="container">
                                <NavLink end={true} className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={item.route}>
                                    <span onClick={() => props.closeMenu()}>{item.name}</span>
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}

MainMenu.propTypes = {
    isOpen: PropTypes.bool,
    menuItems: PropTypes.array,
    closeMenu: PropTypes.func
};

export default MainMenu;