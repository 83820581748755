import React from 'react';
import {Helmet} from 'react-helmet';
import DescriptionBlock from '../components/LayoutElements/DescriptionBlock/DescriptionBlock';
import SubPageList from '../components/SubPageList/SubPageList';

import OurValuesItems from '../config/OurValuesItems';
import OurValuesImage from './../assets/images/section-our-values.svg';

function OurValues() {
		return (
				<div className="main-content" id="our-values">
						<div className="container">
								<Helmet>
										<title>Our Values - Propeller</title>
										<meta name="description"
										      content="Love entrepreneurial spirit, keep improving, deliver together..."/>
								</Helmet>

								<DescriptionBlock
										isLandingBlock={true}
										header="Our Values"
										title="Love entrepreneurial spirit, keep improving, deliver together…"
								/>
						</div>
						<div className="row">
								<div className="col-12">
										<img src={OurValuesImage} className="img-fluid mx-auto d-block" alt="Our Values"/>
								</div>
						</div>
						<section id="values-list">
								<div className="container">
										{OurValuesItems.map((value, key) =>
												<SubPageList title={value.title} description={value.description} key={key}
												             classes="col-lg-11 offset-lg-1 offset-md-1 col-md-10 col-12"/>
										)}
								</div>
						</section>
				</div>
		);
}

export default OurValues;