import React from 'react';
import { Helmet } from 'react-helmet';
import DescriptionBlock from '../components/LayoutElements/DescriptionBlock/DescriptionBlock';

import SubPageList from './../components/SubPageList/SubPageList';
import HowWeWorkImage from './../assets/images/subsection-how-we-work.svg';
import Rocket from './../assets/images/rocket.svg';
import HowWeWorkItems from './../config/HowWeWorkItems';


class HowWeWork extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            heightList: [],
            heightListSum: 0,
            scrollHeight: 0,
            rocketPositionMin: 0,
            rocketPosition: 0,
            rocketPositionMax: 100,
            oldRocketPosition: null
        };

        this.heightOfBlock = this.heightOfBlock.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.moveRocket = this.moveRocket.bind(this);
    }



    componentDidMount() {
        setTimeout(() => {
            this.heightOfBlock();
        }, 300);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    heightOfBlock() {
        let that = this;
        that.setState({
            heightList: this.divRef.clientHeight
        });
    }

    moveRocket() {
        let that = this;
        let startRocket = 0;

        if (this.state.scrollHeight > 0) { //Where start scroll

            //Init value
            that.setState({
                rocketPosition: 0
            });
            //Value of rocket with scrollheight - first section divide by 10
            startRocket = Math.round((that.state.scrollHeight - 260) / 10);
            if (0 <= startRocket && startRocket < 91) {
                that.setState({
                    rocketPosition: startRocket,
                    oldRocketPosition: startRocket
                })
            } else if (startRocket === 91 || startRocket > 91) { //RocketMax
                that.setState({
                    rocketPosition: 91,
                    oldRocketPosition: 91
                })
            } else {
                that.setState({ //RocketMin
                    rocketPosition: 0,
                    oldRocketPosition: 0
                })
            }
        } else {
            that.setState({
                rocketPosition: 0
            });
        }

    }

    handleScroll(e) {
        // When we receive a scroll event, schedule an update.
        // If we receive many updates within a frame, we'll only publish the latest value.
        let scrollTop = e.path[0].scrollingElement.scrollTop;
        this.setState({
            scrollHeight: scrollTop
        })
        this.moveRocket();
    }


    render() {
        return (
            <div className="main-content" id="our-story">
                <div className="container">
                    <Helmet>
                        <title>How We Work - Propeller</title>
                        <meta name="description"
                            content="Innovate, explore, build-upon our and our clients' ideas" />
                    </Helmet>

                    <section className="row">
                        <div className="col-12 col-lg-6 col-md-4">
                            <DescriptionBlock
                                isLandingBlock={true}
                                header="How We Work"
                                title="Innovate, explore, build-upon our and our clients' ideas"
                            />
                        </div>
                        <div className="col-12 col-lg-5 offset-lg-1 col-md-8">
                            <div className="row">
                                <div className="col-12 -padding-0-phone">
                                    <img src={HowWeWorkImage} className="img-fluid mx-auto d-block w-100" alt="Our Values" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="container-fluid">
                    <section id="values-list" className="row -no-background">
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-lg-1 col-2 rocketHolder"><img src={Rocket} id="rocket" className="img-fluid rocket" alt="Rocket" style={{ top: this.state.rocketPosition + '%' }} /></div>
                                <div className="col-lg-11 col-10" id="lista" ref={element => this.divRef = element} >
                                    {HowWeWorkItems.map((value, key) =>
                                        <SubPageList title={value.title} description={value.description} key={key} background={false} classes="col-lg-11 col-md-10 col-12" underclass="col-12" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div >
        )
    };
}

export default HowWeWork;