import React from 'react';
import PropTypes from 'prop-types';

import './SubPageList.scss';

function SubPageList(props) {
	let numDescription = props.description.length;
	let classes = props.classes != null ? props.classes : '';
	let background = props.background ? 'grey-background' : '';
	let underclasses = props.underclasses ? props.underclasses : 'col-lg-7';
	return (
		<div className={`values-list ${classes} ${background}`}>
			<div className={`${underclasses}`}>
				<h2>{props.title}</h2>
				{numDescription <= 1 ? <h5>{props.description}</h5> : props.description.map((value, key) =>
					<h5 key={key}>{value}</h5>
				)}
			</div>
		</div>
	);
}

SubPageList.propTypes = {
	title: PropTypes.string,
	description: PropTypes.array,
	background: PropTypes.bool,
	underclasses: PropTypes.string
};

SubPageList.defaultProps = {
	title: 'Title',
	description: 'Description',
	background: false,
	underclasses: 'col-lg-7'

};

export default SubPageList;