import React from 'react';
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";
import Lottie from 'react-lottie';
import ourValuesAnimation from '../../../assets/lotties/our_values.json';
import { lottieOptions } from '../../../config/PluginSettings';

function OurValuesSection() {
	const windowWidth = window.innerWidth;
	const isSmallerScreen = windowWidth < '992';

	return (
		<section id="our-values" className="row section-our-values">
			<div className="offset-lg-2 col-lg-8">
				<DescriptionBlock
					center={true}
					header="Our Values"
					title="Love entrepreneurial spirit, keep improving, deliver together…"
					buttonText="Our Values"
					navTo="our-values"
				/>
			</div>
			<div className="offset-lg-1 col-lg-10">
				<div className="animation-container animation-our-values">
					{isSmallerScreen
						? <Lottie
							options={{ ...lottieOptions, animationData: ourValuesAnimation }}
							width={'105%'}
						/>
						: <Lottie
							options={{ ...lottieOptions, animationData: ourValuesAnimation }}
						/>
					}
				</div>
			</div>
		</section>
	);
}

export default OurValuesSection;