const MenuItems = [
	{
		name: 'Home',
		route: '/'
	},
	{
		name: 'How We Work',
		route: 'how-we-work'
	},
	{
		name: 'Our Story',
		route: 'our-story'
	},
	{
		name: 'Our Values',
		route: 'our-values'
	},

	{
		name: 'Careers',
		route: 'careers'
	}
];

export default MenuItems;