const OurValuesItems = [
	{
		title: 'Love what you do',
		description: [
			'We do it because we love it! Passion, dedication, fun.'
		]
	},
	{
		title: 'Everyone contributes',
		description: [
			'Don’t expect anybody to hold your hand. We move fast and everybody needs to contribute no matter what the role is. When struggling, ask for help. Embrace the start-up mentality and entrepreneurial spirit.'
		]
	},
	{
		title: 'Keep improving',
		description: [
			'Only monkeys make the same mistakes twice. We don’t settle, we progress and improve on things. We do new stuff!'
		]
	},
	{
		title: 'Deliver',
		description: [
			'We always deliver no matter what. We never let our colleagues or clients down.'
		]
	},
	{
		title: 'We work together',
		description: [
			'We believe in the strength of a team. We leave our ego at home. There are no brilliant jerks here! '
		]
	},
	{
		title: 'We don’t care about your past merits',
		description: [
			'Past is a past. You are expected to make great things here!'
		]

	}

];

export default OurValuesItems;