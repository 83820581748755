import React from 'react';
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";

function OurStorySection() {
	return (
		<section id="our-story" className="row section-our-story">
			<div className="offset-lg-1 col-lg-5">
				<DescriptionBlock
					header="Our Story"
					title="Behind Every Name, There Is a Story"
					buttonText="Our Story"
					navTo="our-story"
				/>
			</div>
			<div className="col-lg-6">
				<div className="animation-container animation-our-story">
				</div>
			</div>
		</section>
	);
}

export default OurStorySection;