import React from "react";
import general from "../../assets/images/careers/general_interview.png";
import technical from "../../assets/images/careers/technical_interview.png";
import final from "../../assets/images/careers/final_interview.png";

const SelectionProcess = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title -center -bold -vertical-margin mb-5">
            Our selection process
          </div>
        </div>              
      </div>
      <div className="row">
        <div className="col-12">
            <div className="subtitle -center -gray -marginbottom50">
            Nothing for you here? Send us a general application and we'll
            get back to you. If there is a role available for you, these are
            the steps of the selection process you can expect:
            </div>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-sm">
            <img src={general} alt="General Interview" className="selection-image"/>
          <h5 className="mb-5 mt-3">General interview</h5>
        </div>
        <div className="col-sm">
            <img src={technical} alt="Technical Interview" className="selection-image"/>
          <h5 className="mb-5 mt-3">Technical interview</h5>
        </div>
        <div className="col-sm">
            <img src={final} alt="Final Interview" className="selection-image"/>
          <h5 className="mb-5 mt-3">Final interview</h5>
        </div>
      </div>
    </div>
  );
};

export default SelectionProcess;
