import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { configureAnchors } from "react-update-url-on-scroll";
import PropTypes from "prop-types";
import "./MainNavbar.scss";
import logo from "../../../assets/images/propeller-logo.svg";
import closeIcon from "../../../assets/images/close.svg";
import openIcon from "../../../assets/images/menu.svg";
// import JobOffers from "../../../api/JobOffers";
import firebase from "./../../../Firebase";
import "firebase/firestore";

function MainNavbar(props) {
  const menuIcon = props.menuIsOpen ? closeIcon : openIcon;
  const transparencyClass = props.menuIsOpen ? "" : "translucent";
  const logoImage = useRef(logo);
  const menuImage = useRef(openIcon);
  const [JobOffers, setJobOffers] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    return db
      .collection("job-ads")
      .where("active", "==", true)
      .onSnapshot((snapshot) => {
        const jobs = [];
        snapshot.forEach((doc) => jobs.push({ ...doc.data(), id: doc.id }));
        setJobOffers(jobs);
        setLoaded(true);
      });
  }, []);

    const page = useCallback(() => {
        if (props.menuIsOpen)
            menuImage.current.src = closeIcon;
        else
            menuImage.current.src = openIcon;
    }, [props.menuIsOpen]);

  configureAnchors({ offset: 96, onSectionEnter: page });

    useEffect(() => {
    page();
    return () => window.removeEventListener("scroll", page, false);
    }, [page]);

  const classes = () => {
    if (!props.menuIsOpen) return transparencyClass;
    else return "";
  };
  return (
    <nav className={`main-navbar ${classes()}`}>
      <div className="container">
        <h1>
                  <Link to="/" {...(props.menuIsOpen && { onClick : props.closeMenu })}>
            <img ref={logoImage} src={logo} alt="Propeller" />
          </Link>
        </h1>
              <Link className="positions" to="careers" {...(props.menuIsOpen && { onClick: props.closeMenu })}>
          <button
            disabled={JobOffers.length === 0 ? true : false}
            className="app-button -outlined -blackborder -round -position"
          >
            {" "}
            {/* {JobOffers.length} positions open */}
            {isLoaded
              ? JobOffers.length === 1
                ? JobOffers.length + " position open"
                : JobOffers.length + " positions open"
              : "Loading"}
          </button>
        </Link>
        <button
          onClick={() => props.onMenuToggle()}
          className="main-menu-toggle"
        >
          <img ref={menuImage} src={menuIcon} alt="Close Menu" />
        </button>
      </div>
    </nav>
  );
}

MainNavbar.propTypes = {
  menuIsOpen: PropTypes.bool,
  onMenuToggle: PropTypes.func,
};

export default MainNavbar;
