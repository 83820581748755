import React from "react";
import { Link } from 'react-router-dom'
import DescriptionBlock from "../../components/LayoutElements/DescriptionBlock/DescriptionBlock";
import growth from "../../assets/images/careers/growth.png";
import mentorship from "../../assets/images/careers/mentorship.png";
import teamwork from "../../assets/images/careers/teamwork.png";
import work_life_balance from "../../assets/images/careers/work_life_balance.png";
import great_location_equipment from "../../assets/images/careers/great_location_equipment.png";
import './CareerView.scss';

const Culture = () => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12">
                <DescriptionBlock
                    isLandingBlock
                    title="Our culture"
                    center
                />
                <h5 className="text-center mt-3 culture-text">
                You already know our <Link to="../our-story">story</Link> and <Link to="../our-values">values</Link> but what else do we cherish at
                Propeller?
                </h5>
            </div>
        </div>

        <div className="row">
            <div className="col-12 mb-5">
                <div className="d-flex justify-content-center culture-row">
                    <div className="culture-image">
                        <img src={growth} alt="Growth and improvement" />
                    </div>
                    <div className="culture-description">
                        <h5>Growth and improvement opportunities</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-5">
                <div className="d-flex justify-content-center culture-row">
                    <div className="culture-image">
                        <img src={mentorship} alt="Mentorship and educations" />
                    </div>
                    <div className="culture-description">
                        <h5>Mentorship/development plans, competence sharing, educations</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-5">
                <div className="d-flex justify-content-center culture-row">
                    <div className="culture-image">
                        <img src={teamwork} alt="Teamwork and teambuilding" />
                    </div>
                    <div className="culture-description">
                        <h5>Teamwork, teambuilding and other organized activities,
                            dynamic and creative environment</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-5">
                <div className="d-flex justify-content-center culture-row">
                    <div className="culture-image">
                        <img src={work_life_balance} alt="Work life balance" />
                    </div>
                    <div className="culture-description">
                        <h5>Work life balance, professional and relaxed atmosphere, competitive salary</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 mb-5">
                <div className="d-flex justify-content-center culture-row">
                    <div className="culture-image">
                        <img src={great_location_equipment} alt="Great location and equipment" />
                    </div>
                    <div className="culture-description">
                        <h5>Great office location, amazing view, good food, good drinks,
                            all necessary equipment</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Culture;
