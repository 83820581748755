const HowWeWorkItems = [
	{
		title: 'Initial meeting',
		description: [
			'We discuss your needs and our ability to meet those needs. Main goal is to understand the scope, budget and time constraints.  '
		]
	},
	{
		title: 'Research',
		description: [
			'We research the solution and make initial estimate with WBS (A work-breakdown structure)'
		]
	},
	{
		title: 'Workshop',
		description: [
			'Requirements are broken down further, we eliminate many of the assumptions and we set-up long term goals.'
		]
	},
	{
		title: 'Pre-study & rapid prototyping',
		description: [
			'Now we clarify how service or the product should work & we do end-user tests. Scope is updated and refined.'
		]
	},
	{
		title: 'Development',
		description: [
			'Now we start developing the product with agile model & close co-operation with you.'
		]
	},
	{
		title: 'Test launch',
		description: [
			'We launch to few users with the goal of finding & solving possible issues.'
		]
	},
	{
		title: 'Launch',
		description: [
			'We ensure that the launch of the product proceeds smoothly without any hiccups.'
		]
	},
	{
		title: 'Marketing',
		description: [
			'We help you attract the users to your service.'
		]
	}
];

export default HowWeWorkItems;