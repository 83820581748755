import React, { useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import "./Accordion.scss";

function Accordion(props) {
    const [isOpen, toggle] = useState(false);
    const [expandClass, setExpandClass] = useState('');
    const [expandedId, setExpandedId] = useState(-1);
    const prevExpanded = usePrevious(expandedId);

    useEffect(() => {
        if (props.expandedAccItemId !== -1) {
            if (isOpen && props.id === props.expandedAccItemId) {
                setExpandClass('expanded');
            }
            else {
                setExpandClass('');
                toggle(false);
            }
            if (props.id === props.jobNumber - 1) {
                var expandedEl = document.getElementsByClassName("expanded");
                if (expandedEl.length > 0 && props.expandedAccItemId !== 0) {
                        const elOffsetTop = expandedEl[0].offsetTop;
                        const offsetTop = elOffsetTop + 200;

                        if (prevExpanded < props.expandedAccItemId) {
                            window.scrollTo({
                                top: offsetTop,
                                behavior: "smooth"
                            });
                        }
                }
                setExpandedId(props.expandedAccItemId);
            }
        }
    }, [isOpen, props.expandedAccItemId, props.id, props.jobNumber, prevExpanded]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

  return (
      <div className="a-item">
          <div onClick={() => { props.onExpand(); toggle(!isOpen); }} className={`a-header ${expandClass}`}>
        <h4>
                  {props.title} <img className="a-arrow" alt="Expand"/>
              </h4>
      </div>

      {expandClass === 'expanded' && (
        <div className="a-body">
          <h5>{props.titleOne}</h5>
                  <br></br>
          <p>{props.jobDescription}</p>
          <h5>{props.titleTwo}</h5>
          <br></br>
          <ul>
            {props.requirements &&
              props.requirements.map((value, key) => (
                <li key={key}>{value}</li>
              ))}
          </ul>
          <h5>{props.titleFour}</h5>
          <br />
          <ul>
            {props.bonusSkills &&
              props.bonusSkills.map((value, key) => <li key={key}>{value}</li>)}
          </ul>
          <h5>{props.titleThree}</h5>
          <br></br>
          <p>{props.whatWeOffer}</p>
            <br></br>
            <div className="acc-apply-section">
                <p className="acc-apply-cell">
                    <span>{props.contact}<a href="mailto:career@propeller.ba">career@propeller.ba</a>
                        <span> or use the apply button</span>   
                        <span className="hand-right">&#9758;</span>
                    </span>
                </p>
                <div className="acc-apply-cell">
                    <button className="acc-apply-btn" onClick={(e) => { e.stopPropagation(); props.applyNow(props.id) }}>Apply now</button>
                </div>
            </div>
        </div>
      )}
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Accordion;
