import React from "react";
import DescriptionBlock from "../../components/LayoutElements/DescriptionBlock/DescriptionBlock";

function Apply(props) {
  return (
    <div className="apply-position">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <DescriptionBlock
                isLandingBlock
                title="Apply now"
                center
            />
          </div>
        </div>
        <form
          id="apply-form"
          action="#"
          method="post"
          encType="multipart/form-data"
          className="apply-form"
        >
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="form-group">
                <select
                  className="form-control"
                  name="position"
                  value={props.formData.position}
                  onChange={(e) => props.handleFormChange(e)}
                  required
                >
                  <option value="">Choose position</option>
                  {props.jobOffers.map((item, key) => (
                    <option key={key} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                  <option value="General Application">General Application</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <div className="form-group">
                <input
                  type="text"
                  onFocus={(e) => props.clearMessage(e)}
                  placeholder="Your name"
                  className="form-control"
                  name="sender_name"
                  onChange={(e) => props.handleFormChange(e)}
                />
              </div>
           </div>
            <div className="col-md-6 offset-md-3" style={{ display: 'none' }}>
                <div className="form-group">
                    <input
                        type="text"
                        onFocus={(e) => props.clearMessage(e)}
                        placeholder="Company"
                        className="form-control"
                        style={{ display: 'none' }}
                        name="sender_company"
                        onChange={(e) => props.handleFormChange(e)}
                    />
                </div>
            </div> {/*This one is added to prevent bots to submit the form*/}
            <div className="col-md-6 offset-md-3">
              <div className="form-group">
                <input
                  type="email"
                  onFocus={(e) => props.clearMessage(e)}
                  placeholder="Email"
                  className="form-control"
                  name="sender_email"
                  onChange={(e) => props.handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6 offset-md-3">
              <div className="form-group">
                <input
                  type="text"
                  onFocus={(e) => props.clearMessage(e)}
                  placeholder="CV Link"
                  className="form-control"
                  name="cvlink"
                  onChange={(e) => props.handleFormChange(e)}
                />
              </div>
            </div>
            <div className="col-md-6 offset-md-3 order-4">
              <div className="form-group">
                <textarea
                  placeholder="Brief introduction"
                  onFocus={(e) => props.clearMessage(e)}
                  className="form-control"
                  name="message"
                  rows="4"
                  onChange={(e) => props.handleFormChange(e)}
                ></textarea>
              </div>
            </div>
           
                      <div className="col-md-6 offset-md-3 order-4">
              <div className="col-lg-12 -defaultheight">
                  {props.isSent && (<span className="file-name -center">
                                      <span className="-green">
                                        Thank you for your application!
                                      </span>
                                  </span>
                    )}
                    {props.isSent === false && (<span className="file-name -center">
                                                  <span className="-red">
                                                    Failed to send your application. Please try again.
                                                  </span>
                                              </span>
                    )}
              </div>
              <div className="col-10 offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                {/* <div className="form-group">
                  <div className="col-md-12 uploadwapper">
                    <label htmlFor="attachment" className="app-button -attach -round -white"><i className="fa fa-paperclip -smallmarginright"></i>Attach CV</label>
                    <input id="attachment" type="file" className="attach"
                      onFocus={(e) => props.clearMessage(e)}
                      name="attachment" onChange={(e) => props.handleDocumentUpload(e)} />
                  </div>
                </div> */}
                <div className="col-md-12 form-group text-center">
                    {props.sending ?
                        (<div className="spinner-border" role="status" style={{ color: "#2b3177" }}>
                            <span className="sr-only">Loading...</span>
                        </div>) :
                        <button
                            className="app-button -apply -centered -round -position -blueborder -bluetext -marginRight0"
                            value="Apply now"
                            type="submit"
                            disabled={!props.isValidForm}
                            onClick={(e) => props.formSubmit(e)}
                        >
                            Apply now
                        </button>
                      }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Apply;
