import React from 'react';
import DescriptionBlock from "../../LayoutElements/DescriptionBlock/DescriptionBlock";
import Lottie from 'react-lottie';
import aboutAnimation from '../../../assets/lotties/about.json';
import { lottieOptions } from '../../../config/PluginSettings';

function AboutSection() {
	return (
		<section id="about" className="row section-about">
			<div className="offset-lg-1 col-lg-5">
				<DescriptionBlock
					isLandingBlock={true}
					header="About"
					title="Propelling ideas into the future"
					description="We are Propeller, a dedicated team of experienced tech experts. We’ll do the heavy lifting for you."
					buttonText="read more"
					navTo="our-story"
				/>
			</div>
			<div className="col-lg-6">
				<div className="animation-container animation-about">
					<Lottie
						options={{ ...lottieOptions, animationData: aboutAnimation }}
					/>
				</div>
			</div>
		</section>
	);
}

export default AboutSection;