import React from 'react';

import AboutSection from "../components/HomeComponents/AboutSection/AboutSection";
import HowWeWorkSection from "../components/HomeComponents/HowWeWorkSection/HowWeWorkSection";
import OurStorySection from "../components/HomeComponents/OurStorySection/OurStorySection";
import OurValuesSection from "../components/HomeComponents/OurValuesSection/OurValuesSection";
import CareerSection from "../components/HomeComponents/CareerSection/CareerSection";
import { Helmet } from "react-helmet";

function Home() {
	return (
		<div className="main-content">
			<Helmet>
				<title>Propeller - Propelling ideas into the future</title>
				<meta name="description" content="We are a dedicated team of experienced tech experts. We'll do the heavy lifting for you." />
			</Helmet>
			<div className="container">
				<AboutSection />
				<HowWeWorkSection />
				<OurStorySection />
				<OurValuesSection />
			</div>
			{/*<BlogSection/> No blog posts yet */}
			<div className="container">
				<CareerSection />
			</div>

		</div>
	);
}

export default Home;